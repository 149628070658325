<template>
  <div id="m1port-modal-dialog" class="modal-dialog-container">
    <div class="modal-dialog-window" :key="status">
      <div id="m1port-modal-dialog-main-message" class="modal-dialog-main-message"></div>
      <div id="m1port-modal-dialog-sub-message" class="modal-dialog-sub-message"></div>
      <button id="m1port-modal-dialog-yes-button" type="button" v-on:click="onYesClicked()">
        {{ intl('labels.buttons.yes') }}
      </button>  
      <button id="m1port-modal-dialog-no-button" type="button" v-on:click="onNoClicked()">
        {{ intl('labels.buttons.no') }}
      </button>  
      <button id="m1port-modal-dialog-close-button" type="button" v-on:click="onCloseClicked()">
        {{ intl('labels.buttons.close') }}
      </button> 
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModalDialog',
  inject: ['m1port'],
 
  methods: {
    onYesClicked() {
      this.m1port.modal.onYesClicked();
    },

    onNoClicked() {
      this.m1port.modal.onNoClicked();
    },

    onCloseClicked() {
      this.m1port.modal.onCloseClicked();
    }
  },

  data() {
    return {
      intl: this.m1port.t,
      status: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .modal-dialog-container {
    /** set to flex on visible ! */
    display: none;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding: 0;
    background: rgba(238, 127, 0, 0.5);
    border: 5px solid #ee7f00;
    border-radius:  5px;
    box-sizing: border-box;
    height: 100vh;
    width: 100%;
    z-index: 10000;
    position: absolute;
    top: 0;
    left: 0;
  }

  .modal-dialog-window {
    padding: 2em;
    border-radius: 9px;
    box-shadow: 0 0 5px 4px #ee7f00;
    background: rgba(225, 225, 225, 1);
  }

  .modal-dialog-main-message {
    font-weight: bold;
    font-size: 1.2em;
    margin: 1em 0.5em 2em 0.5em;
  }

  .modal-dialog-sub-message {
    padding-bottom: 1.5em;
    font-size: 1.2em;
    font-weight: bold;
    color: #ee7f00;
  }

  button {
    background: #ee7f00;
    color: white;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 0.75em 1em;
    clear: both;
    font-size: 1.25em;
    margin: 1em 1.5em;;
    font-weight: bold;
    min-width: 8em;
    
    cursor: pointer;
  }

  button:active {
    background: rgba(235, 235, 235, 1);
    color: #ee7f00;
  }

  .error-block {
    height: 1.5em;
    color: red;
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 1em;
  }

  button:focus {
    box-shadow: 0 0 5px 4px #ee7f00;
  }
</style>
