<template>
    <div class="block">
        <div v-if="m1port.currentTask != Tasks.GOODBY_PATIENT"
          class="arrow-right" v-on:click="onClicked" @touchstart="onClicked"></div>
    </div>
  </template>
  
  <script>

  import Events from '@/model/Events.js';
  import Tasks from '@/model/Tasks.js';
  
  export default {
    name: 'CtrlNext',
    inject: ['m1port'],
    
    methods: {
      onClicked() {
        if (this.m1port.currentTask != Tasks.GOODBY_PATIENT) {
          this.m1port.logger.log(Events.SHEET_GO_NEXT
            + " - Sender: CtrlNext");
          this.$emit(Events.SHEET_GO_NEXT);
        }
      }
    },

    data() {
      return {
        Tasks: Tasks
      }
    }
  }
  </script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .block {
    width: 4em;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;
    align-content: center;

    background: rgba(235, 235, 235, 1);
    padding: 0;
    margin: 0; 
  }

  .arrow-right {
    width: 0; 
    height: 0; 
    border-top: 1.5em solid transparent;
    border-bottom: 1.5em solid transparent; 
    border-left: 1.5em solid #ee7f00; 
  }

  .arrow-right:hover {
    border-left: 1.5em solid black; 
  }

</style>
  