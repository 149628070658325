<template>
  <div>
    <div id="clock-block" class="clock-block">
      <div class="clock">
        <p style="font-weight: normal">{{ date }}</p>
        <p>{{ time }}</p>
      </div> 
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppClockBlock',
    
    mounted() {
      setInterval(() => {
        this.date = this.getDate();
        this.time = this.getTime();
      }, 1000);
    },
    
    methods: {
      getDate: () => {
        const date = new Date();
        
        return date.getDate() + "/" 
          + (date.getMonth() + 1)
          + "/" + date.getFullYear();
      },
      
      getTime: () => {
        const date = new Date();
        let h = date.getHours();
        let m = date.getMinutes();
        let s = date.getSeconds();
        
        
        h = (h <10 ? "0" : "") + h;
        m = (m <10 ? "0" : "") + m;
        s = (s <10 ? "0" : "") + s;
        
        return h + ":" + m + ":" + s;
      }
    },
    
    data() {
      return {
        date: this.getDate(),
        time: this.getTime()
      }
    }
  }
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
  .clock-block {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 0;
    align-items: center;
    padding: 0;
  }  
  
  .clock {
    padding: 0.65em 1.5em;
    background: #ee7f00;
    border-radius: 5px;
    border: 1px solid gray;
  }
  
  p {
    margin: 0.15em 0;
    padding: 0;
    text-align: center;
    font-size: 1.1em;
    font-weight: bold;
    font-family: "Verdana";
    color: white;
  }
</style>
  