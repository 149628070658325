<template>
    <div>
      <div class="menu-block">
        <div class="container secondary">
          <img class="icon small" alt="Small Text Icon"
            src="./../assets/text-size-icon.svg"
            v-on:click="setSmallFonts()" />
        </div>
        <div class="container secondary">
          <img class="icon" alt="Big Text Icon"
            src="./../assets/text-size-icon.svg"
            v-on:click="setBigFonts()" />
        </div>
        <div class="container" v-if="m1port.loggedIn" >
          <div class = "left-icon-text">
            <span v-html="intl ('labels.buttons.endSheet')"></span>
          </div>
          <img class="icon" alt="Logout Icon" 
          :title="intl('actions.main.logout')"
          src="./../assets/logout-icon.svg"
          v-on:click="terminateSheet()" />
        </div>
      </div>
    </div>
  </template>

<script>

import Events from '@/model/Events.js';

export default {
  name: 'AppHeaderMenuBlock',
  
  inject: ['m1port'],
  
  props: {
    
  },
  
  methods: {
    terminateSheet() {
      this.m1port.modal.create({
        mainMessage: 'modal.messages.doYouWantToEndSheet',
        subMessage: 'modal.messages.noDataWillBeSaved',
        onYes: () => {
          this.m1port.logger.log(Events.TERMINATE_SHEET
            + " - Sender: AppHeaderMenuBlock");
          this.$emit(Events.TERMINATE_SHEET);
        },
        onNo:() => {}
      });

      this.m1port.logger.log(Events.MODAL_WINDOW_SHOW
        + " - Sender: AppHeaderMenuBlock");
      this.$emit(Events.MODAL_WINDOW_SHOW);
    },

    setBigFonts() {
      this.m1port.setBigFonts();
    },

    setSmallFonts() {
      this.m1port.setSmallFonts();
    }
  },
  
  data() {
    return {
      intl: this.m1port.t
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
  .menu-block {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin: 0;
    align-items: center;
    padding: 0 1em;;
  }

  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin: 0;
    align-items: center;
    padding: 0;
    cursor: pointer;
  }

  .container.secondary {
    margin: 0 1em 0 0;
    border: 1px solid gray;
    border-radius: 5px;
    padding-right: 0.5em;
    padding-left: 0.5em;
    background: rgba(245, 245, 245, 1);
  }

  .icon {
    width: 2em;
    display: inline-block;
    margin: 0.75em;
    padding: 0.3em;
    border-radius: 3px;
    background: #ee7f00;
    cursor: pointer;
  }

  .icon.small {
    width: 1.2em;
    padding: 0.7em;
  }

  .left-icon-text {
    font-weight: bold;
    margin: 0.5em 0 0.5em 1.25em;
    color: #ee7f00; 
  }
</style>
