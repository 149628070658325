<template>
  <div id="text-touch-preview-container" class="text-touch-preview-container" style="visibility: hidden;">
    <div id="text-touch-preview" class="text-touch-preview"> bla bla bla</div>
  </div>
  <div id="questions-block" class="questions-block">
    <p class="questions-set-counter"> 
      {{ intl('labels.screens.questionSet') }}: 
      {{ m1port.activeQuestionNumber + 1 }} / {{ m1port.getQuestionBlocksNumber() }}
    </p>

    <div class="question-block"
      v-for="question in questions" :key="question.___renderKey">
      <p class="question-text">
        <span class="q-text">{{ question.questionText }}</span>
      </p>
      <div class="question-answers">

        <div v-if="question.answerType.toLowerCase() == 'singlechoice'" >
          <div class="radio-answer-container" 
            v-for="ansKey in question.answerKeys.split('|')" 
            :key="'q-' + question.id + '-' + ansKey">
            <label class="radio-choice-container"
              @touchstart="radioBoxLabelTouched($event, question, ansKey)">
              <input class="radio-answer" type="radio" 
                :disabled="!isQuestionActive(question)"
                :checked="question.answers[ansKey] == true"
                :name="'ans-q' + question.id" 
                @click="radioBoxClicked($event, question, ansKey)" 
                @touchstart="radioBoxTouched($event, question, ansKey)"/> 
              {{ question.answerTexts[ansKey] }}
            </label>
          </div>   
        </div>

        <div v-if="question.answerType.toLowerCase() == 'multiplechoice'" >
          <div class="checkbox-answer-container"
            v-for="ansKey in question.answerKeys.split('|')" 
            :key="'q-' + question.id + '-' + ansKey">
            <label class="checkbox-choice-container"
              @touchstart="checkBoxLabelToched($event, question, ansKey)">
              <input class="checkbox-answer" type="checkbox" 
                :name="'ans-q' + question.id" 
                :checked="question.answers[ansKey] == true"
                :disabled="!isQuestionActive(question)"
                @click="checkBoxClicked($event, question, ansKey)" 
                @touchstart="checkBoxToched($event, question, ansKey)"/> 
              {{ question.answerTexts[ansKey] }}
            </label>
          </div>   
        </div>

        <div v-if="question.answerType.startsWith('text')">
          <input type="text" class="text-choice"
            :disabled="!isQuestionActive(question)" 
            v-model="question.answers['text_value']" 
            @touchstart="textAnswerTouched($event, question)"
            @click="textAnswerClicked($event, question)"
            @keyup="showTextPreview(true, $event.target.value)"/>
        </div>

      </div>
    </div>
  </div> 
</template>
    
<script>
  export default {
    name: 'QuestionPatientScreen',
    
    inject: ['m1port'],

    methods: {
      showTextPreview(mode, text) {
        mode = (mode === true ? true : false);
        if (typeof text != 'string') {
          text = "";
        }
        const previewEl = document
          .getElementById("text-touch-preview-container");

        if (previewEl) {
          if (mode === true) {
            previewEl.style.setProperty("visibility", "visible", "important");
            const textContainerEl = previewEl.querySelector("#text-touch-preview");
            textContainerEl.innerText = text;
          } else {
            previewEl.style.removeProperty("visibility");
          }
        }
      },

      textAnswerTouched(evt, question) {
        this.m1port.logger.log(evt, question);
        const target = evt.target;

        target.focus();


        let tagName = target.tagName;
        const clockEl = document.getElementById("clock-block");
        if (tagName && typeof tagName == "string") {
          tagName = tagName.toUpperCase();
        }
        if (tagName.startsWith('INPUT') || tagName.startsWith('TEXTAREA')) {
          if (target && clockEl) {
            target.blur();
            clockEl.focus();
            clockEl.blur();
            target.focus();
          }
        }
        evt.stopPropagation();    
        this.showTextPreview(true, target.value);    
      },

      textAnswerClicked(evt, question) {
        this.m1port.logger.log(evt, question);
        evt.target.focus();
        evt.stopPropagation();
      },

      isQuestionActive(question) {
        if (question.id == question.dependsOnQuestionId) {
          return true;
        }
        const qLen = this.questions.length;
        for (let i = 0; i < qLen; i++) {
          const q = this.questions[i];
          if (q.id == question.id) {
            continue;
          }

          if (question.dependsOnQuestionId == q.id) {
            if (q.answers['yes']) {
              return true;
            } 
          }
        }
        for (const ans in question.answers) {
          delete question.answers[ans];
        }
        return false;
      },

      checkBoxClicked(evt, question, answerKey) {
        const target = evt.target;

        if(target.disabled) {
          return;
        }

        if(!target.checked) {
          delete question.answers[answerKey];
        } else {
          question.answers[answerKey] = true;
        }
        /*this.m1port.logger.log("CLICK-CHECKBOX: ", 
          answerKey, question.answers);*/
      },

      checkBoxToched(evt, question, answerKey) {
        const target = evt.target;

        if(target.disabled) {
          return;
        }

        if(target.checked) {
          delete question.answers[answerKey];
          target.checked = false;
        } else {
          question.answers[answerKey] = true;
          target.checked = true;
        }
        /*this.m1port.logger.log("TOUCH-CHECKBOX: ", 
          answerKey, question.answers);*/
      },

      checkBoxLabelToched(evt, question, answerKey) {
        let target = evt.target.querySelector("input.checkbox-answer[type=checkbox]");

        if (!target) {
          this.m1port.logger.log("TOUCH-CHECKBOX-LABEL: target not found");
          return;
        }

        if(target.disabled) {
          return;
        }

        if(target.checked) {
          delete question.answers[answerKey];
          target.checked = false;
        } else {
          question.answers[answerKey] = true;
          target.checked = true;
        }
      },

      radioBoxClicked(evt, question, answerKey) {
        const target = evt.target;   
        
        if(target.disabled) {
          return;
        }
        
        if(!target.checked) {
          delete question.answers[answerKey];
        } else {
          for (const ansKey in question.answers) {
            delete question.answers[ansKey];
          }
          question.answers[answerKey] = true;
        }
        question.___renderKey = this.m1port.uuid();
        /*this.m1port.logger.log("CLICK-RADIO: ",
          answerKey, question.answers);*/
      },

      radioBoxTouched(evt, question, answerKey) {
        const target = evt.target;   
        
        if(target.disabled) {
          return;
        }

        if (!target.checked) {
          for (const ansKey in question.answers) {
            delete question.answers[ansKey];
          }
          question.answers[answerKey] = true;
          target.checked = true;
        } 
        question.___renderKey = this.m1port.uuid();
        /*this.m1port.logger.log("TOUCH-RADIO: ", 
          answerKey, question.answers);*/
      },
      
      radioBoxLabelTouched(evt, question, answerKey) {
        let target = evt.target.querySelector("input.radio-answer[type=radio]");

        if (!target) {
          this.m1port.logger.log("TOUCH-RADIOBOX-LABEL: target not found");
          return;
        }
                
        if(target.disabled) {
          return;
        }

        if (!target.checked) {
          for (const ansKey in question.answers) {
            delete question.answers[ansKey];
          }
          question.answers[answerKey] = true;
          target.checked = true;
        } 
        question.___renderKey = this.m1port.uuid();
      },


      transferFocus() {
        const parent = document.getElementById("questions-block");
        const handler = (e) => {
          const activeEl = document.activeElement;
          let tagName = document.activeElement.tagName;
          const clockEl = document.getElementById("clock-block");
          if (tagName && typeof tagName == "string") {
            tagName = tagName.toUpperCase();
          }
          if (tagName.startsWith('INPUT') || tagName.startsWith('TEXTAREA')) {
            if (activeEl && clockEl) {
              activeEl.blur();
              clockEl.focus();
              this.showTextPreview(false);
              e.stopPropagation();
            }
          }
        };
        parent.addEventListener("click", handler);
        parent.addEventListener("touchstart", handler);
      }

    },

    mounted() {
      this.m1port.enableBlockScrolling('questions-block');
      this.transferFocus();
    },

    data() {
      return {
        intl: this.m1port.t,
        questions: this.m1port.sheet.questions[this.m1port.activeQuestionNumber],
        status: true
      }
    }
  }
</script>
    
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .questions-block {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: left;
    align-items: flex-start;
    padding: 0.75em 0;
    background: white;
    box-sizing: border-box;
    height: 100%;
    flex: 1;

    padding: 2em 3em;
  } 
  
  .dependent-questions {
    width: 100%;
  }
  .dependent-question {
    margin: 1em 0;
    width: 100%;
  }

  .question-block {
    width: 95%;
  }

  .question-text {
    text-align: left;
    font-weight: bold;
    font-size: 1.25em;
  }
  
  .question-answers {
    text-align: left;
    font-size: 1.25em;
    margin: 0.5em 0 1em 1em;
  }
  
  .radio-answer-container {
    display: inline-block;
  }

  .checkbox-answer-container {
    display: block;

  }

  .checkbox-choice-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    margin-bottom: 0.25em;
  }

  .radio-choice-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-right: 2.5em;
    float: left;
  }

  .text-choice {
    padding: 0.25em 0.5em;
    font-size: 1.5em;
    width: 100%;
  }

  .questions-set-counter {
    font-weight: bold;
    font-size: 1.35em;
    padding-bottom: 0.2em;
    border-bottom: 2px solid #ee7f00;
  }

  .checkbox-answer {
    width: 2em;
    min-width: 2em;
    max-width: 2em;
    height: 2em;
    min-height: 2em;
    max-height: 2em;
    margin-right: 1.25em;
  }

  .radio-answer {
    width: 2em;
    min-width: 2em;
    max-width: 2em;
    height: 2em;
    min-height: 2em;
    max-height: 2em;
    margin-right: 1.25em;
  }

  .text-touch-preview-container {
    visibility: hidden;
    position:absolute; 
    height: 5em; 
    width: 100%; 
    z-index: 99999; 
    text-align: center;
  }

  .text-touch-preview {
    width: 75%;
    height: 2em;
    font-weight: bold;
    background: white;
    color: black;
    margin: 1em auto 0 auto;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 12px 8px #ee7f00;
    padding: 1em 0 0 0;
    font-size: 1.2em;
  }
</style>    