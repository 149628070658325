<template>
  <div class="login-container" :key="language">
    <div v-on:click="changeLanguage(language)" class="language-option" 
      v-for="language in languages" 
      :value="language.code" :key="language.id">
      <img class="flag-icon" :src="getCountryFlagUrl(language.code)" />
      {{ language.code.toUpperCase() }}
    </div>
  
    <label>
      <input type="text" 
        :placeholder="intl('labels.forms.caseId')" 
        v-model="caseId"
        @keyup.enter="loginPatient()" /> 
    </label>
    <div class="error-block">
      {{ errorMessage }}
    </div>
    <button type="button" v-on:click="loginPatient()">
      {{ intl('labels.buttons.loginPatient') }}
    </button>  
  </div>
</template>

<script>
import Events from '@/model/Events.js';
import Tasks from '@/model/Tasks.js';
import ReqActions from '@/model/ReqActions.js';

export default {
  name: 'LoginScreen',
  inject: ['m1port', 'qconnector'],
  
  methods: {
    getCountryFlagUrl(code) {
      return require(`@/assets/icons/flags/${code}.svg`);
    },
   
    changeLanguage(lang) {
      this.m1port.language = lang.code.toLowerCase();
      window.localStorage.setItem("m1port_patient_lang", this.m1port.language);
      this.$emit(Events.LANGUAGE_SELECTED, lang);
      this.m1port.logger.log(Events.LANGUAGE_SELECTED
        + " - Sender: LoginScreen. Value: " + this.m1port.language);
    },

    loginPatient() {
      this.qconnector.fetch(
        { "action": ReqActions.PATIENT_DATA,
          "caseId": this.caseId,
          "languageCode": this.m1port.language,
          "languageId": this.m1port.getLanguageId()
        },
        (data) => {
          const patient = data.patient;
          const sheet = data.sheet;
          if (!patient) {
            this.errorMessage = this.intl('texts.patientNotFound');
            this.m1port.logout();
            setTimeout(() =>{
              this.errorMessage = null;
            }, 2500);
          } else {
            const eventData =  {'patient': patient, 'sheet' : sheet};
            this.m1port.login(this.caseId, patient, sheet);

            this.$emit(Tasks.PATIENT_DATA, eventData);
            this.m1port.logger.log(Events.PATIENT_DATA
              + " - Sender: LoginScreen. Value: ", eventData);
          }
        },
        (err) => {
          this.m1port.logger.log(err);
        }
      );
    }
  },
  
  data() {
    return {
      intl: this.m1port.t,
      language: this.m1port.language,
      caseId: null,
      errorMessage: null,
      countries: this.m1port.countries,
      languages: this.m1port.languages
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.login-container {
  padding: 2em 1.5em;
  flex: 1;
}

label {
  display: block;
  margin: 0.5em;
  font-size: 1.5em;
  font-weight: bold;
  color: orange;
}

input[type=text] {
  width: 12em;
  font-size: 1.1em;
  margin: 1em 0;
  padding: 0.35em 0.75em;
}

.language-option {
  display: inline-flex;
  height: 1.5em;
  font-weight: bold;
  font-size: 1.25em; 
  background: white;
  align-content: center;
  align-items: center;
  justify-content: center;

  padding: 6px 3px;
  margin: 1.5em 0.5em 2em 0.5em;
  border: 2px solid black;
  cursor: pointer;

  background: rgba(235, 235, 235, 1);
}

.flag-icon {
  padding: 0 8px 0 5px;
  height: 1.25em;
}

button {
  background: #ee7f00;
  color: white;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 0.75em 1.25em;
  clear: both;
  font-size: 1.5em;
  margin-top: 1em;
  font-weight: bold;
  min-width: 9em;
  
  cursor: pointer;
}

button:active {
  background: rgba(235, 235, 235, 1);
  color: #ee7f00;
}

.error-block {
  height: 1.5em;
  color: red;
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 1em;
}

button:focus {
  box-shadow: 0 0 5px 4px #ee7f00;
}
</style>