<template>
  <div id="signature-block" class="signature-block">
    <div class="signature-block-info">{{ intl('texts.signWithYourFinger') }}</div>
  </div>
</template>

<script>

export default {
  name: 'SignatureBlock',
  
  inject: ['m1port'],

  mounted() {
    this.m1port.enableSignatureBlock(
      'signature-block', 
      { width: '30em', height: '13em'});
  },
  
  data() {
    return {
      intl: this.m1port.t
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .signature-block {
    width: 30em;
    height: 15em;
    margin: 1em 0;
    background: rgba(225, 225, 225, 1);
    border: 1px solid black;
  }

  .signature-block-info {
    height: 1.5em;
    padding: 0.25em;
    background: #ee7f00;
    color: white;
  }
</style>