<template>
  <div class="finish-block">
    <button class="finish-button"
      v-on:click="terminateSheet()" 
      @touchstart="terminateSheet()">
      {{ intl('labels.buttons.finish') }}
    </button>
  </div>
</template>

<script>
import Events from '@/model/Events.js';
export default {
  name: 'FinishBlock',
  
  inject: ['m1port'],

  methods: {
    terminateSheet() {
      this.m1port.logger.log(Events.TERMINATE_SHEET
        + " - Sender: FinishBlock. "
        + "Save data to DB is requested.");
      this.$emit(Events.TERMINATE_SHEET, true);
    }
  },
  
  data() {
    return {
      intl: this.m1port.t
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .finish-block {
    display: block;
    /*padding-top: 3em;*/
  }

  .finish-button {
    background: #ee7f00;
    color: white;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 0.75em 1.25em;
    font-size: 1.5em;
    /*margin-top: 1em;*/
    font-weight: bold;
    min-width: 9em;
    cursor: pointer;
  }

  .finish-button:active {
    background: rgba(235, 235, 235, 1);
    color: #ee7f00;
  }
  
  .finish-button:focus {
    box-shadow: 0 0 5px 4px #ee7f00;
  }
</style>
