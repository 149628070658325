import cfg from '@/config/config.js';

const qConnector = {
  stage: cfg.stage
};

qConnector.getServerUrl = () => {
  return qConnector.getConnectionCfg().baseServerUrl;
};

qConnector.getConnectionCfg = () => {
  const server = cfg.connector.server ;
  const isSSL = cfg.connector.ssl === true;
  const protocol = isSSL ? "https://" : "http://";
  const port = cfg.connector.port;
  const reqPort = (!isNaN(port) && port > 22) ? ":" + port : "";
  const reqPath = "/" + qConnector.stage + cfg.connector.path;
  return {
    baseServerUrl: protocol + server + reqPort,
    protocol: protocol,
    serverUrl: protocol + server + reqPort + reqPath,
    apiUrl: protocol + server + reqPort + reqPath + "/api",
  }
};

qConnector.fetch = (params, onResponse, onError) => {
  const connCfg = qConnector.getConnectionCfg(); 
  if (!params || typeof params != 'object') {
    return;
  }

  if (!onResponse || typeof onResponse != 'function') {
    onResponse = function() {};
  }

  if (!onError || typeof onError != 'function') {
    onError = function() {};
  }

  const body = {
    "client": 'patient'
  };

  for (const param in params) {
    body[param] = params[param];
  }
  const reqOpts = {
    method: "POST",
    headers: { "Content-Type": 'application/json' },
    body: JSON.stringify(body)
  };

  fetch(connCfg.apiUrl + "/task.php", reqOpts)
  .then( (response) => {
    return response.json();
  })
  .then((data) => {
    onResponse(data);
  })
  .catch((error) => {
    const code = 777;
    onError(code, error);
    console.log("QConnector::fetch failed: " + code, error);
  });
}

export default qConnector;