<template>
    <div id="main-content-container" class="main-container">
      <CtrlPrev v-if="m1port.loggedIn" @sheet-go-prev="onSheetGoPrev"/>

      <LoginScreen v-if="!m1port.loggedIn" 
        @language-selected="onLanguageSelected"
        @patient-data="onPatientData"/>

      <WelcomePatientScreen v-if="m1port.loggedIn && m1port.currentTask == Tasks.WELCOME_PATIENT" :key="status"/>
      <QuestionPatientScreen v-if="m1port.loggedIn && m1port.currentTask == Tasks.QUESTION" :key="status"/>
      <InformationSheetScreen v-if="m1port.loggedIn && m1port.currentTask == Tasks.PATIENT_INFORMATION_SHEET" :key="status"/>
      <GoodbyPatientScreen v-if="m1port.loggedIn && m1port.currentTask == Tasks.GOODBY_PATIENT" :key="status"
        @terminate-sheet="onTerminateSheet" />

      <CtrlNext v-if="m1port.loggedIn"  @sheet-go-next="onSheetGoNext"/>
    </div>
  </template>
  
  <script>
  import LoginScreen from './LoginScreen.vue';
  import WelcomePatientScreen from './WelcomePatientScreen.vue';
  import QuestionPatientScreen from './QuestionPatientScreen.vue';
  import InformationSheetScreen from './InformationSheetScreen.vue';
  import GoodbyPatientScreen from './GoodbyPatientScreen.vue';
  import CtrlPrev from './CtrlPrev.vue';
  import CtrlNext from './CtrlNext.vue';

  import Events from './../model/Events.js';
  import Tasks from '@/model/Tasks';
  
  export default {
    name: 'AppMainContent',
   
    inject: ['m1port'],
    
    components: {
      LoginScreen,
      WelcomePatientScreen,
      QuestionPatientScreen,
      InformationSheetScreen,
      GoodbyPatientScreen,
      CtrlPrev,
      CtrlNext
    },

    methods: {
      onLanguageSelected(data) {
        this.m1port.logger.log(Events.LANGUAGE_SELECTED 
          + " - Receiver: AppMainContent", data);
        this.$emit(Events.LANGUAGE_SELECTED, data);
        this.status = !this.status;
      },

      onPatientData(data) {
        this.m1port.logger.log(Events.PATIENT_DATA 
            + " - Receiver: AppMainContent", data);
        this.patient = data.patient;
        this.sheet = data.sheet;

        this.$emit(Events.PATIENT_DATA, data);
        this.status = !this.status;
      },

      onSheetGoNext() {
        this.m1port.logger.log(Events.SHEET_GO_NEXT 
          + " - Receiver: AppMainContent");
        this.$emit(Events.SHEET_GO_NEXT);
        this.status = !this.status;
      },

      onSheetGoPrev() {
        this.m1port.logger.log(Events.SHEET_GO_PREV 
          + " - Receiver: AppMainContent");
        this.$emit(Events.SHEET_GO_PREV);
        this.status = !this.status;
      },

      onTerminateSheet(data) {
        this.m1port.logger.log(Events.TERMINATE_SHEET
          + " - Receiver: AppMainContent. Value: ", data);
        this.$emit(Events.TERMINATE_SHEET, data);
        this.status = !this.status;
      }
    },

    mounted() {
      if (this.m1port.loggedIn) {
        const el = document.getElementById('main-content-container');
        let startX = 0, startY = 0, distX = 0, distY = 0;

        el.addEventListener("touchstart", (evt) => {
          let touchobj = evt.changedTouches[0]; 
          startX = parseInt(touchobj.clientX); 
          startY = parseInt(touchobj.clientY);
          evt.preventDefault();
        });

        el.addEventListener("touchmove", (evt) => {
          let touchobj = evt.changedTouches[0]; 
          distX = parseInt(touchobj.clientX) - startX;
          distY = parseInt(touchobj.clientY) - startY;
          evt.preventDefault();
        });

        el.addEventListener("touchend", (evt) => {
          //let touchobj = evt.changedTouches[0]; 
          if (!(evt.target instanceof HTMLCanvasElement)) {
              if (distX > this.m1port.configuration.ui.minHorizontalDragDistanceForNextPrevEvents) {
              this.m1port.logger.log(Events.SHEET_GO_PREV
                + " - Sender: CtrlPrev");
              this.$emit(Events.SHEET_GO_PREV);
            } else if (distX < -this.m1port.configuration.ui.minHorizontalDragDistanceForNextPrevEvents) {
              this.m1port.logger.log(Events.SHEET_GO_NEXT
                + " - Sender: CtrlNext");
              if (this.m1port.currentTask != Tasks.GOODBY_PATIENT) {
                this.$emit(Events.SHEET_GO_NEXT, {dX: distX, dY: distY});
              }
            }
            distX = 0;
            distY = 0;
          } else {
            this.m1port.logger.log("Ignored slide left/right event for canvas element!");
          }

          evt.preventDefault();
        });
      }
    },

    props: {
      language: String,
      task: String
    },
    
    data() {
      return {
        Tasks: Tasks,
        status: true, 
        patient: null,
        sheet: null
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .main-container {
      padding: 0;
      margin: 0;
      display: flex;
      background: white;
      overflow: auto;
      flex: 1;
      box-sizing: border-box;
      align-items: flex-start;
      justify-content: center;
      align-content: center;
      flex-direction: row;
      padding: 0;
      margin: 0;

      overflow-y: hidden; 
      overflow-x: hidden; 
    }
  </style>