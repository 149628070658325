<template>
    <div id="welcome-patient-container" class="welcome-patient-container">
      <p class="text-block" v-html="m1port.getTemplate('welcome', m1port.activeWelcomeSheetNumber)"></p>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'WelcomePatientScreen',
   
    inject: ['m1port'],

    mounted() {
      this.m1port.enableBlockScrolling('welcome-patient-container');
    },

    data() {
      return {
        intl: this.m1port.t
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .welcome-patient-container {
      padding: 1em 1.5em;
      display: flex;
      background: white;
      flex: 1;
      box-sizing: border-box;
      align-items: flex-start;
      justify-content: flex-start;
      align-content: flex-start;
      flex-direction: column;
    }

    .salutation {
      font-weight: bold;
      font-size: 1.1em;
    }

    .text-block {
      display: block;
      width: 100%;
      text-align: left;
    }
  </style>