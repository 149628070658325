const userCfg = window.__m1port;
const conn =  userCfg && typeof userCfg == 'object' ? userCfg.connector : null;

export default {
  "stage": userCfg && typeof userCfg.stage == 'string' ? userCfg.stage : "",
  "connector": {
    "server": (conn && conn.server && typeof conn.server == 'string') ? conn.server : "m1port",
    "port": (conn && conn.port && typeof conn.port == 'number') ? conn.port : 443,
    "path": (conn && conn.path && typeof conn.path == 'string') ? conn.path : "/connector",
    "ssl": (conn && conn.ssl && typeof conn.ssl == 'boolean') ? conn.ssl : true    
  },

  "ui": {
    "minHorizontalDragDistanceForNextPrevEvents": 300
  }
}