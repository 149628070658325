<template>
  <ModalDialog />

  <AppHeader :title="intl('appTitle')" 
    :key="status" :language="m1port.language" :progress="m1port.getProgress()"
    @terminate-sheet="onTerminateSheet"
    @modal-window-show="onModalWindowShow" />

  <AppMainContent 
    :key="status" :language="m1port.language" :task="m1port.currentTask"
    @language-selected="onLanguageSelected" 
    @patient-data="onPatientData" 
    @sheet-go-next="onSheetGoNext"
    @sheet-go-prev="onSheetGoPrev"
    @terminate-sheet="onTerminateSheet"/>

  <AppFooter :language="m1port.language" :key="status" />
</template>

<script>
import ModalDialog from './components/ModalDialog.vue';
import AppHeader from './components/AppHeader.vue';
import AppMainContent from './components/AppMainContent.vue';
import AppFooter from './components/AppFooter.vue';

import Events from './model/Events.js';
import Tasks from './model/Tasks.js';

export default {
  name: 'App',
  inject: ['m1port'],
  
  components: {
    ModalDialog,
    AppHeader,
    AppMainContent,
    AppFooter
  },  
  
  methods: {
    onModalWindowShow() {
      this.m1port.logger.log(
        Events.MODAL_WINDOW_SHOW
        + " - Receiver: App");
      this.m1port.modal.show();  
    },

    onLanguageSelected(data) {
      this.m1port.logger.log(Events.LANGUAGE_SELECTED 
        + " - Receiver: App", data);
      this.$emit(Events.LANGUAGE_SELECTED, data);
      this.status = !this.status;
    },

    onPatientData(patient) {
      this.m1port.logger.log(Events.PATIENT_DATA 
        + " - Receiver: App", patient);  
      this.m1port.setCurrentTask(Tasks.WELCOME_PATIENT);
      this.status = !this.status;
    },

    onSheetGoNext() {
      this.m1port.logger.log(Events.SHEET_GO_NEXT 
        + " - Receiver: App");
      this.m1port.goNext();
      this.status = !this.status;
    },

    onSheetGoPrev() {
      this.m1port.logger.log(Events.SHEET_GO_PREV 
        + " - Receiver: App");
      this.m1port.goPrev();
      this.status = !this.status;
    },

    onTerminateSheet(withSave) {
      this.m1port.logger.log(Events.TERMINATE_SHEET
        + " - Receiver: App. Save data to DB was "
        + (withSave == true ? '' : 'NOT') + " requested.");
      this.m1port.logout(withSave);
      this.status = !this.status;
    }
  },
  
  mounted() {
    const el = document.body;
    if (!this.m1port.loggedIn) {
     /* el.addEventListener("touchstart", function(evt){
        evt.preventDefault();
      }, {passive: false});*/

      el.addEventListener("touchmove", function(evt){
        evt.preventDefault();
      }, {passive: false});

     /* el.addEventListener("touchend", function(evt){
        evt.preventDefault();
      }, {passive: false});*/
    }
  },

  data() {
    return {
      intl: this.m1port.t,
      info: '',
      status: true
    }
  }
}
</script>

<style>

html {
  height: 100%;
}

body {
  margin: 0;
  border-radius: 0;
  min-height: 100%;
  box-sizing: border-box;
  height: 100vh;
  background: #ee7f00;

  overflow-y: hidden; 
  overflow-x: hidden; 

  user-select: none;
}

#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  font-family: Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
  display: flex;
  flex-flow: column;
  flex-wrap: nowrap;
  
  padding: 5px;
  height: 100vh;
  
  box-sizing: border-box;

  /*font-size: 95%;*/
}
</style>