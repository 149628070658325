<template>
    <div id="goodby-patient-container" class="goodby-patient-container">
      <div class="text-block" v-html="m1port.getTemplate('goodby')"></div>
      <div class="goodby-action-block">
        <SignatureBlock v-if="m1port.loggedIn && m1port.currentTask == Tasks.GOODBY_PATIENT/* && m1port.endScreen*/"  />
        <FinishBlock v-if="m1port.loggedIn && m1port.currentTask == Tasks.GOODBY_PATIENT /*&& m1port.endScreen*/" 
          @terminate-sheet="onTerminateSheet" />
      </div>
    </div>
    
  </template>
  
  <script>
  import SignatureBlock from './SignatureBlock.vue';
  import FinishBlock from './FinishBlock.vue';
  import Tasks from '@/model/Tasks';
  import Events from '@/model/Events';

  export default {
    name: 'GoodbyPatientScreen',
   
    inject: ['m1port'],

    components: {
      SignatureBlock,
      FinishBlock
    },

    mounted() {
      this.m1port.enableBlockScrolling('goodby-patient-container');
    },

    methods: {
      onTerminateSheet(data) {
        this.m1port.logger.log(Events.TERMINATE_SHEET
          + " - Receiver: GoodbyPatientScreen. "
          + "Value: ", data);
        this.$emit(Events.TERMINATE_SHEET, data);
      }
    },  

    data() {
      return {
        Tasks: Tasks,
        intl: this.m1port.t
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .goodby-patient-container {
      padding: 1em 1.5em;
      display: flex;
      background: white;
      flex: 1;
      box-sizing: border-box;
      align-items: center;
      justify-content: flex-start;
      align-content: flex-start;
      flex-direction: column;
    }

    .goodby-action-block {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }

    .text-block {
      display: block;
      width: 100%;
      text-align: left;
      flex: 1;
    }
  </style>