<template>
  <div class="footer">
    <div class="container left"> 
        &copy; M1 Med Beauty {{ year }} | v{{ m1port.version}} | {{ m1port.getAppBarInfos() }}
    </div>
    <div class="container right"> 
      <span class="data">
        {{ m1port.getPatientFullName() ? m1port.getPatientFullName() + '&nbsp; | &nbsp;' : '' }} 
        {{ intl('labels.forms.language') }} : {{ language.toUpperCase() }}
      </span>
    </div>
  </div>
</template>
  
<script>
  export default {
    name: 'AppFooter',
    
    inject: ['m1port'],
    
    props: {
      language: String
    },

    data() {
      return {
        intl: this.m1port.t,
        year: (new Date()).getFullYear()
      }
    }
  }
</script>
  
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0.75em 0;
    background: rgba(235, 235, 235, 1);
    border-top: 2px solid gray;
    border-radius: 0 0 5px 5px;
    box-sizing: border-box;
  }    
  span.data {
    font-size: 1.15em;
    color: #ee7f00;
  }
  
  .container {
    flex-grow: 1;
  }
  
  .container.left {
    text-align: left;
    padding: 0 0 0 2em;
    width: 50%;
  }
    
  .container.right {
    text-align: right;
    padding: 0 2em 0 0;
    width: 50%;
  }
</style>  