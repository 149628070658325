import { createApp } from 'vue'
import {v4 as UUID} from 'uuid';
import App from './App.vue'
import Tasks from '@/model/Tasks.js';
import ReqActions from '@/model/ReqActions.js';

import QConnector from '@/api/Connector.js';

import Config from '@/config/config.js'

window.localStorage.setItem("m1port_patient_debug", true);

const m1port = {
  uuid: UUID,
  loggedIn: false,
  patient: {
    name: '',
    salutation: ''
  },
  version: window.__m1port && window.__m1port.version 
   ? window.__m1port.version : null,
  currentTask: Tasks.LOGIN_PATIENT,
  configuration: Config,
  sheet: null,
  activeQuestionNumber: 0,
  activeInfoSheetNumber: 0,
  activeWelcomeSheetNumber: 0,
  openQuestionsNumber: 0,
  progress: 0,
  fullProgress: 0,
  endScreen: false,
  scrollingBlockTargets: {},
  modal: {
    mainMessage: null,
    subMessage: null,
    yesButton: true,
    noButton: true,
    closeButton: false,
    userOnYes: () => {},
    userOnNo: () => {},
    userOnClose: () => {},
    create: (data) => {
      if (!data || typeof data != 'object') { 
        data = {};
      }
      m1port.modal.mainMessage 
        = data.mainMessage || null;
      m1port.modal.subMessage 
        = data.subMessage || null;
      m1port.modal.yesButton 
        = data.yesButton === false ? false : true;
      m1port.modal.noButton 
        = data.noButton === false ? false : true;
      m1port.modal.closeButton 
        = data.closeButton === true ? true : false;
      m1port.modal.isError 
        = data.isError === true ? true : false;
      m1port.modal.errorCode 
        = data.errorCode ? data.errorCode : null;

      if(data.onYes && typeof data.onYes == 'function') {
        m1port.modal.userOnYes = data.onYes;
      }
      if(data.onNo && typeof data.onNo == 'function') {
        m1port.modal.userOnNo = data.onNo;
      }
      if(data.onClose && typeof data.onClose == 'function') {
        m1port.modal.userOnClose = data.onClose;
      }
      if (data.displayImediately === true) {
        m1port.modal.show();
      }
    },
    onYesClicked: () => {
      m1port.modal.userOnYes();
      m1port.modal.close();
    },
    onNoClicked: () => {
      m1port.modal.userOnNo();      
      m1port.modal.close();
    },
    onCloseClicked: () => {
      m1port.modal.userOnClose();      
      m1port.modal.close();
    },
    show: () => {
      const modalEl = document
        .getElementById("m1port-modal-dialog");
      if (modalEl) {
        const mainMsgEl = document.getElementById(
            'm1port-modal-dialog-main-message');
        if (mainMsgEl) {
          mainMsgEl.innerHTML 
            = m1port.t(m1port.modal.mainMessage);
        }
        const subMsgEl = document.getElementById(
          'm1port-modal-dialog-sub-message');
        if (subMsgEl) {
          subMsgEl.innerHTML 
            = m1port.t(m1port.modal.subMessage);
          if (m1port.modal.isError === true) {
            subMsgEl.innerHTML += ": " 
              + m1port.modal.errorCode;
            subMsgEl.style.setProperty("color", 
              "red", "important");
          } else {
            subMsgEl.style.removeProperty("color");
          }
        }

        let btnEl = document
          .getElementById("m1port-modal-dialog-yes-button");
        if (btnEl) {
          if (m1port.modal.yesButton === true) {
            btnEl.style.removeProperty('display');
          } else {
            btnEl.style.setProperty('display', 'none', 'important');
          }
        }

        btnEl = document
            .getElementById("m1port-modal-dialog-no-button");
        if (btnEl) {
          if (m1port.modal.noButton === true) {
            btnEl.style.removeProperty('display');
          } else {
            btnEl.style.setProperty('display', 
              'none', 'important');
          }
        }

        btnEl = document
            .getElementById("m1port-modal-dialog-close-button");
        if (btnEl) {
          if (m1port.modal.closeButton === true) {
            btnEl.style.removeProperty('display');
          } else {
            btnEl.style.setProperty('display', 
              'none', 'important');
          }
        }
        modalEl.style.setProperty("display", 
          "flex", "important");
      }
    },
    close: () => {
      const modalEl = document
        .getElementById("m1port-modal-dialog");
      if (modalEl) {
        modalEl.style.setProperty("display", "none");
        const mainMsgEl = document.getElementById(
          'm1port-modal-dialog-main-message');
        if (mainMsgEl) {
          mainMsgEl.innerHTML = '';
        }
        const subMsgEl = document.getElementById(
          'm1port-modal-dialog-sub-message');
        if (subMsgEl) {
          subMsgEl.innerHTML = '';
        }
      }
      m1port.modal.userOnYes = () => {};
      m1port.modal.userOnNo = () => {};

      m1port.modal.yesButton = true;
      m1port.modal.noButton = true;
      m1port.modal.closeButton = false;
      m1port.modal.isError = false;
      m1port.modal.errorCode = null;
    }
  },
  t: (key) => {
    if (!key) {
      return '';
    }

    let lang = "de";
    if (window.localStorage) {
      lang = window.localStorage
        .getItem("m1port_patient_lang");
    }
    const t = m1port.translations[lang];
    const keys = key.trim().split(".");
    let ref = t;
    if (t) {
      keys.some((k) => {
        if (ref[k]) {
          ref = ref[k];
        } else {
          return false; 
        }
      });
    }
    return (ref && typeof ref === "string" ? ref : key + '[' + lang + ']');
  },
  logger: console
};

m1port.setSmallFonts = () => {
  const target = document.body;
  //const target = document.getElementById("main-content-container");
  target.style.setProperty("font-size", "1em");
};

m1port.setBigFonts = () => {
  const target = document.body;
  //const target = document.getElementById("main-content-container");
  target.style.setProperty("font-size", "1.15em");
};

m1port.getPatientFullName = (includeSalutation) => {
  const patient = m1port.patient;
  let fullName = '';
  if (patient) {
    if (patient.name){
      fullName += patient.name ? patient.name : '';
    } else if (patient.firstName || patient.lastName) {
      fullName += patient.firstName ? patient.firstName + ' ' : '';
      fullName += patient.lastName ? patient.lastName : '';
    }
  }
  if (includeSalutation === true && patient.salutation 
    && typeof patient.salutation == 'string') {
    //fullName = patient.salutation.trim() + " " + fullName;
  }
  return fullName;
};

m1port.getTemplate = (templateName, index) => {
  const sheet = m1port.sheet;
  let template = null;
  const questionPagesNr
    = Object.keys(m1port.sheet.questions).length;
  if (sheet && sheet.templates 
    && templateName && sheet.templates[templateName]) {
    if ((index || index == 0) && sheet.templates[templateName][index]) {
      template = sheet.templates[templateName][index];
    } else {
      template = sheet.templates[templateName];
    }
  }
  if (template && Array.isArray(template) && template.length == 1) {
    template = template[0];
  }
  if (template && typeof template == 'string') {
    template = template.replaceAll("%%patient-name%%", 
      m1port.getPatientFullName(true));
    template = template.replaceAll("%%open-questions-number%%", 
      m1port.openQuestionsNumber);
    template = template.replaceAll("%%total-questions-number%%", 
      questionPagesNr);
    template = template.replaceAll("%%treatment-title%%", 
      sheet.text);

    if (m1port.openQuestionsNumber == questionPagesNr) {
      template = template.replaceAll("%%new-anamnese-data%%", ""); 
      template = template.replaceAll("%%existing-anamnese-data%%", "hidden"); 
    } else {
      template = template.replaceAll("%%new-anamnese-data%%", "hidden"); 
      template = template.replaceAll("%%existing-anamnese-data%%", ""); 
    }
  }    
  return template;
};

m1port.getLanguageId = () => {
  let langCode = m1port.language;
  if (typeof langCode != 'string') {
    return -1;
  }
  langCode = langCode.trim();
  if (langCode.length != 2) {
    return -1;
  }
  for (const idx in m1port.languages) {
    const lang = m1port.languages[idx];
    if (lang.code == langCode) {
      return lang.id;
    }
  }
  return -1;
};

m1port.getQuestionBlocksNumber = () => {
  return m1port.sheet && m1port.sheet.questions 
    ? Object.keys(m1port.sheet.questions).length : 0;
};

m1port.login = (caseId, patient, sheet) => {
  m1port.caseId = caseId;
  m1port.patient = patient;
  m1port.sheet = sheet;
  m1port.loggedIn = true;
  m1port.endScreen = false;
  m1port.activeQuestionNumber = 0;
  m1port.activeInfoSheetNumber = 0;
  m1port.activeWelcomeSheetNumber = 0;

  const questionPagesNr
    = Object.keys(m1port.sheet.questions).length;
  const welcomePagesNr
    = Object.keys(m1port.sheet.templates.welcome).length;
  const infoSheetsPagesNr
    = Object.keys(m1port.sheet.templates.informationSheets).length;
  m1port.progress = 0;
  m1port.fullProgress = questionPagesNr + welcomePagesNr + infoSheetsPagesNr;

  const questionBlocks = m1port.sheet && m1port.sheet.questions 
    && Array.isArray(m1port.sheet.questions) ? m1port.sheet.questions : null;
  m1port.openQuestionsNumber = questionPagesNr;

  if(questionBlocks) {
    questionBlocks.forEach((qBlock) => {
      if (qBlock && Array.isArray(qBlock)) {
        qBlock.forEach((q) => {
          q.___renderKey = m1port.uuid();
        });
        const isAnswered = qBlock.some((q) => {
          return Object.keys(q.answers).length > 0;
        });
        if (isAnswered) {
          m1port.openQuestionsNumber--;
          return;
        }
      }
    });
  }
};

m1port.logout = (storeDataToDb) => {
  m1port.activeQuestionNumber = 0;
  m1port.activeInfoSheetNumber = 0;
  m1port.activeWelcomeSheetNumber = 0;
  m1port.progress = 0;
  m1port.fullProgress = 0;
  m1port.loggedIn = false;
  m1port.endScreen = false;
  m1port.openQuestionsNumber = 0;
  m1port.setCurrentTask(Tasks.LOGIN_PATIENT);

  if (storeDataToDb === true) {
    const signImgData = m1port.getSignatureImage(m1port.signatureBlockId);
    m1port.clearSignatureImage(m1port.signatureBlockId);
    m1port.sheet.signatureImage = {
      data: signImgData.data,
      contentType: signImgData.contentType
    };
    m1port.savePatientSheetDataToDb();
  } else if (m1port.patient && typeof m1port.patient == 'object'){
    m1port.cancelPatientSheet();
  }

  m1port.patient = {
    name: '',
    salutation: ''
  };
  m1port.sheet = null;
  m1port.caseId = null;

  m1port.setSmallFonts();
};

m1port.cancelPatientSheet = () => {
  QConnector.fetch(
    { "action": ReqActions.CANCEL_PATIENT_DATA,
      "appointmentId": m1port.caseId,
    },
    (data) => {
      m1port.logger.log(data);
    },
    (err) => {
      this.m1port.logger.error("Error fetching intl data from server. ", err);
    }
  );
};

m1port.savePatientSheetDataToDb = () => {
  QConnector.fetch(
    { "action": ReqActions.SAVE_PATIENT_DATA,
      "caseId": m1port.caseId,
      "sheet": m1port.sheet,
      "patient": m1port.patient
    },
    (data) => {
      if (data && data.completed === true) {
        m1port.modal.create({
          mainMessage: 'modal.messages.completedAndDataSaved',
          subMessage: 'modal.messages.thankYou',
          yesButton: false,
          noButton: false,
          closeButton: true,
          displayImediately: true
        });
      } else {
        m1port.modal.create({
          mainMessage: 'modal.messages.errorWhenSavingPatientData',
          subMessage: 'modal.messages.errorCode',
          isError: true,
          errorCode: data.errorCode,
          yesButton: false,
          noButton: false,
          closeButton: true,
          displayImediately: true
        });
      }
    },
    (err) => {
      this.m1port.logger.error("Error fetching intl data from server. ", err);
    }
  );
};

m1port.getProgress = () => {
  return Math.round(m1port.progress * 100 / m1port.fullProgress);
};

m1port.enableBlockScrolling = (blockId) => {
  const target = document.getElementById(blockId);
  if (!target) {
    m1port.logger.log("M1PORT::enableBlockScrolling() => ERROR: "
      + "trying to enable scrolling on a non existing element <" 
      + blockId + ">.");
    return;
  }

  if (!m1port.scrollingBlockTargets[blockId]) {
    m1port.scrollingBlockTargets[blockId] = {};
  }

  const onWheel = (evt) => {
    const origEvt = evt.originalEvent;
    if (origEvt) {
      target.scrollTop += origEvt.deltaY;
    }
  };
  target.addEventListener('wheel', onWheel);
  m1port.scrollingBlockTargets[blockId].wheel = onWheel;
  
  let lastTouch = null;
  const onTouchStart = (evt) => {
    lastTouch = evt.touches[0];
  };
  window.addEventListener('touchstart', onTouchStart);
  m1port.scrollingBlockTargets[blockId].touchstart = onTouchStart;

  const onTouchEnd = () => {
    lastTouch = null;
  };
  window.addEventListener('touchend', onTouchEnd);
  m1port.scrollingBlockTargets[blockId].touchend = onTouchEnd;

  const onTouchMove = () => {
    const currentTouch = event.changedTouches[0];
    if (lastTouch) {
      target.scrollTop += lastTouch.clientY - currentTouch.clientY;
    }
    lastTouch = currentTouch;
  };
  window.addEventListener('touchmove', onTouchMove);
  m1port.scrollingBlockTargets[blockId].touchmove = onTouchMove;

  target.style.setProperty('height', '100%', 'important');
  target.style.setProperty('overflow', 'auto', 'important');
};

m1port.disableBlockScrolling = (blockId) => {
  const target = document.getElementById(blockId);
  if (!target) {
    m1port.logger.log("M1PORT::disableBlockScrolling() => ERROR: "
      + "trying to disable scrolling on a non existing element <" 
      + blockId + ">.");
    return;
  }

  if (!m1port.scrollingBlockTargets[blockId]) {
    m1port.logger.log("M1PORT::disableBlockScrolling() => ERROR: "
      + "trying to disable scrolling on an element <" 
      + blockId + "> for which M1PORT::enableBlockScrolling was "
      + " not used before to enable the scrolling.");
    return;
  }

  const onWheel = m1port.scrollingBlockTargets[blockId].wheel;
  target.removeEventListener('wheel', onWheel);

  const onTouchstart = m1port.scrollingBlockTargets[blockId].touchstart;
  window.removeEventListener('touchstart', onTouchstart);

  const onTouchEnd = m1port.scrollingBlockTargets[blockId].touchend;
  window.removeEventListener('touchend', onTouchEnd);

  const onTouchMove = m1port.scrollingBlockTargets[blockId].touchmove;
  window.removeEventListener('touchmove', onTouchMove);
};

m1port.enableSignatureBlock = (containerId, canvasStyle) => {
  const parentEl = document.getElementById(containerId);
  if (!parentEl) {
    m1port.logger.log("M1PORT::enableSignatureBlock() => ERROR: "
      + "trying to enable signature on a non existing parent element <" 
      + containerId + ">.");
    return;
  }

  const canvasEl = document.createElement('canvas');
  const canvasCtx = canvasEl.getContext('2d');
  let dragging = false;
  let activeColour = "black";
  let cursorPoint = { x: 0, y: 0 };

  const signatureBlockId = containerId + '-canvas-sign';
  canvasEl.setAttribute('id', signatureBlockId);

  m1port.signatureBlockId = signatureBlockId;

  const getLocationFrom = (evt) => {
    const location = { x: 0, y: 0 };

    if (evt.constructor.name === "TouchEvent") {            
      const bounds = evt.target.getBoundingClientRect();
      const touch = evt.targetTouches[0];
      
      location.x = touch.pageX - bounds.left;
      location.y = touch.pageY - bounds.top;
    } else {            
        location.x = evt.offsetX;
        location.y = evt.offsetY;
    }
    return location;
  };

  const onMouseDown = (evt) => {
    dragging = true;

    const location = getLocationFrom(evt);
    cursorPoint.x = location.x;
    cursorPoint.y = location.y;

    canvasCtx.lineWidth = 2;
    canvasCtx.lineCap = 'round';
    canvasCtx.filter = 'blur(1px)';
    canvasCtx.beginPath();
    canvasCtx.moveTo(cursorPoint.x, cursorPoint.y);
    canvasCtx.strokeStyle = activeColour;
  };
  canvasEl.addEventListener('mousedown', onMouseDown);

  const onMouseUp = () => {
    dragging = false;
  };
  canvasEl.addEventListener('mouseup', onMouseUp);

  const onMouseOut = () => {
    dragging = false;
  };
  canvasEl.addEventListener('mouseout', onMouseOut);

  const onMouseMove = (evt) => {
    if(!dragging) {
      return;
    }
    const location = getLocationFrom(evt);
    canvasCtx.lineTo(location.x, location.y);
    canvasCtx.stroke();
  };
  canvasEl.addEventListener('mousemove', onMouseMove);

  const onTouchStart = (evt) => {
    if (evt.target == canvasEl) {
      evt.preventDefault();                 
      onMouseDown(evt); 
    }
  }
  document.body.addEventListener("touchstart", onTouchStart, false);

  const onTouchEnd = (evt) => {
    if (evt.target == canvasEl) {
      evt.preventDefault();                 
      onMouseUp(evt); 
    }
  }
  document.body.addEventListener("touchend", onTouchEnd, false);

  const onTouchMove = (evt) => {
    if (evt.target == canvasEl) {
      evt.preventDefault();                 
      onMouseMove(evt); 
    }
  }
  document.body.addEventListener("touchmove", onTouchMove, false);

  if (canvasStyle && typeof canvasStyle == 'object') {
    for (const cssProp in canvasStyle) {
      canvasEl.style.setProperty(cssProp, canvasStyle[cssProp]);
    }
  }
  parentEl.appendChild(canvasEl);

  const width = canvasEl.clientWidth;
  const height = canvasEl.clientHeight;

   // If it's resolution does not match change it
   if (canvasEl.width !== width || canvasEl.height !== height) {
    canvasEl.width = width;
    canvasEl.height = height;
   }
};

m1port.getSignatureImage = (signatureCanvasElemId, decoded) => {
  const canvasEl = document.getElementById(signatureCanvasElemId);
  if (canvasEl && canvasEl instanceof HTMLCanvasElement) {
    const contentType = "image/png";
    let base64Img = canvasEl.toDataURL(contentType);
    if (decoded == true) {
      base64Img = base64Img.replace("data:" 
        + contentType + ";base64,", "");
      return {
        data: window.atob(base64Img),
        contentType: "image/png"
      };
    } else {
      return {
        data: base64Img,
        contentType: "image/png"
      };
    }
  } 
  return null;
};

m1port.clearSignatureImage = (signatureCanvasElemId) => {
  const canvasEl = document.getElementById(signatureCanvasElemId);
  if (canvasEl && canvasEl instanceof HTMLCanvasElement) {
    const canvasCtx = canvasEl.getContext('2d');
    canvasCtx.clearRect(0, 0, canvasEl.width, canvasEl.height);
  } 
};

m1port.setCurrentTask = (newTask) => {
  if (!newTask || typeof newTask != 'string') {
    m1port.logger.log("M1PORT::setCurrentTask() => ERROR: "
     + "trying to set a task which's value is not a string.", newTask);
     return;
  }

  if (Tasks[newTask]) {
    m1port.currentTask = Tasks[newTask];
  } else {
    let taskIdentifier = newTask.toUpperCase();
    taskIdentifier = taskIdentifier.replaceAll("-", "_");
    if (Tasks[taskIdentifier]) {
      m1port.currentTask = Tasks[taskIdentifier];
    } else {
      m1port.logger.log("M1PORT::setCurrentTask() => ERROR: "
        + "trying to set a task which does not exists <" 
        + newTask + ">. Also tried with <" + taskIdentifier + ">.");
    }
  }
};

m1port.goNext = () => {
  m1port.logger.log("M1PORT::goNext() => OLD TASK: '" 
    + m1port.currentTask + "'. Progress: " + m1port.progress);
  if (m1port.currentTask == Tasks.WELCOME_PATIENT) {
    if (m1port.sheet && m1port.sheet.templates && m1port.sheet.templates.welcome 
      && m1port.sheet.templates.welcome[m1port.activeWelcomeSheetNumber + 1]) {
      m1port.activeWelcomeSheetNumber++;
      m1port.logger.log("M1PORT::setCurrentTask() => INFO: "
      + " going to next welcome sheet <" +  m1port.activeWelcomeSheetNumber + ">.");
    } else {
      m1port.logger.log("M1PORT::setCurrentTask() => INFO: "
        + " no more welcome sheets, going to first question <" +  m1port.activeQuestionNumber + ">.");
      m1port.setCurrentTask(Tasks.QUESTION);
    }
    m1port.progress++;
  } else if (m1port.currentTask == Tasks.QUESTION) {
    if (m1port.sheet.questions &&  m1port.sheet.questions[m1port.activeQuestionNumber + 1]) {
      m1port.activeQuestionNumber++;
      m1port.logger.log("M1PORT::setCurrentTask() => INFO: "
        + " going to next question <" +  m1port.activeQuestionNumber + ">.");
    } else {
      m1port.logger.log("M1PORT::setCurrentTask() => INFO: "
        + " no more questions, going to Patient-Info-Sheet-Screen <" 
        +  m1port.activeInfoSheetNumber + ">.");
      m1port.setCurrentTask(Tasks.PATIENT_INFORMATION_SHEET);
    }
    m1port.progress++;
  } else if (m1port.currentTask == Tasks.PATIENT_INFORMATION_SHEET) {
    if (m1port.sheet && m1port.sheet.templates && m1port.sheet.templates.informationSheets 
        && m1port.sheet.templates.informationSheets[m1port.activeInfoSheetNumber + 1]) {
      m1port.activeInfoSheetNumber++;
      m1port.logger.log("M1PORT::setCurrentTask() => INFO: "
      + " going to next info sheet <" +  m1port.activeInfoSheetNumber + ">.");
    } else {
      m1port.logger.log("M1PORT::setCurrentTask() => INFO: "
        + " no more info sheets, going to Goodby-Patient-Screen.");
      m1port.setCurrentTask(Tasks.GOODBY_PATIENT);
    }
    m1port.progress++;
    if (m1port.progress == m1port.fullProgress) {
      m1port.endScreen = true;
    }
  } else if (m1port.currentTask == Tasks.GOODBY_PATIENT) {
    m1port.logger.log("M1PORT::setCurrentTask() => INFO: "
      + " completed all tasks, going back to login screen.");
    m1port.logout();
  } 
  if (m1port.progress > m1port.fullProgress) {
    m1port.progress = m1port.fullProgress;
  }
  m1port.logger.log("M1PORT::goNext() => NEW TASK: " 
    + m1port.currentTask + "'. Progress: " + m1port.progress);
};

m1port.goPrev = () => {
  m1port.logger.log("M1PORT::goPrev() => OLD TASK: '" 
    + m1port.currentTask + "'. Progress: " + m1port.progress);
  if (m1port.currentTask == Tasks.WELCOME_PATIENT) {
    if (m1port.sheet && m1port.sheet.templates && m1port.sheet.templates.welcome 
      && m1port.sheet.templates.welcome[m1port.activeWelcomeSheetNumber - 1]) {
      m1port.activeWelcomeSheetNumber--
      m1port.logger.log("M1PORT::setCurrentTask() => INFO: "
      + " going to previous welcome sheet <" +  m1port.activeWelcomeSheetNumber + ">.");
    } else {
      m1port.activeQuestionNumber = 0;
      m1port.activeInfoSheetNumber = 0;
      m1port.setCurrentTask(Tasks.WELCOME_PATIENT);
      m1port.logger.log("M1PORT::setCurrentTask() => INFO: "
      + "going to first question <" +  m1port.activeQuestionNumber + ">.");
      m1port.logger.log("M1PORT::setCurrentTask() => INFO: "
        + " no more welcome sheets, no back option available <" 
        +  m1port.activeQuestionNumber + ">.");
    } 
    m1port.progress--;
  } else if (m1port.currentTask == Tasks.QUESTION) {
    if (m1port.sheet.questions && m1port.sheet.questions[m1port.activeQuestionNumber - 1]) {
      m1port.activeQuestionNumber--;
      m1port.logger.log("M1PORT::setCurrentTask() => INFO: "
        + " going to previous question, new active question is <" 
        +  m1port.activeQuestionNumber + ">.");
    } else {
      m1port.logger.log("M1PORT::setCurrentTask() => INFO: "
        + " no more questions, going back to Patient-Welcome-Screen <" 
        +  m1port.activeInfoSheetNumber + ">.");
      m1port.setCurrentTask(Tasks.WELCOME_PATIENT);
    }
    m1port.progress--;
  } else if (m1port.currentTask == Tasks.PATIENT_INFORMATION_SHEET) {
    if (m1port.sheet && m1port.sheet.templates && m1port.sheet.templates.informationSheets 
        && m1port.activeInfoSheetNumber > 0) {
      m1port.activeInfoSheetNumber--;
      m1port.logger.log("M1PORT::setCurrentTask() => INFO: "
      + " going to previous info sheet <" +  m1port.activeInfoSheetNumber + ">.");
    } else {
      m1port.logger.log("M1PORT::setCurrentTask() => INFO: "
        + " no more info sheets, going to last question <" 
        + m1port.activeQuestionNumber + ">.");
      m1port.setCurrentTask(Tasks.QUESTION);
    }
    m1port.progress--;
  } else if (m1port.currentTask == Tasks.GOODBY_PATIENT) {
    m1port.progress--;
    m1port.logger.log("M1PORT::setCurrentTask() => INFO: "
      + " going to Patient-Info-Sheet-Screen<" 
      + m1port.activeInfoSheetNumber + ">.");
    m1port.setCurrentTask(Tasks.PATIENT_INFORMATION_SHEET);
  } 
  if (m1port.progress < 0) {
    m1port.progress = 0;
  }
  m1port.logger.log("M1PORT::goPrev() => NEW TASK: " 
    + m1port.currentTask + "'. Progress: " + m1port.progress);
};

m1port.handleFatalServerError = () => {
  const messageEn = "Server error. Please contact the IT department.";
  const messageDe = "Serverfehler. Bitte wenden Sie sich an die IT-Abteilung.";
  const errEl = document.createElement("div");
  const reloadBtn = document.createElement("button");

  errEl.classList.add("server-error-block")
  errEl.innerHTML = messageEn + "<br />" + messageDe + "<br />";

  reloadBtn.innerHTML = "Reload / Neu laden";
  errEl.appendChild(reloadBtn);

  document.body.style.setProperty("text-align", "center");

  document.body.insertBefore(errEl, document.body.firstChild);
  reloadBtn.addEventListener('click', () => {
    location.reload();
  });
};

m1port.getAppBarInfos = () => {
  let info = "";
  if (m1port.currentTask) {
    let tParts = m1port.currentTask.split('-');
    tParts.forEach(part => {
      if (part[0]) {
        info +=part[0];
      }
    });
  } 
  info += m1port.endScreen === true ? '1' : '0';
  info += m1port.loggedIn === true ? '1' : '0';
  info += '' + m1port.progress;

  return info;
}

m1port.prepare = () => {
  QConnector.fetch(
    { "action": ReqActions.PATIENT_APP_VERSION },
    (data) => {
      const serverVersion = data.version;
      const localVersion = m1port.version;
      const matchedVersion = serverVersion == localVersion;
      const matchingRequired = data.matchingRequired === true;
      m1port.logger.log("M1PORT::prepare() => INFO: "
      + " server reported version <" + serverVersion 
      + ">. Local version is <" + localVersion + ">. The two versions are " 
      + (matchedVersion ? "" : "not ") + "equal. Matching required <" 
      + (matchingRequired ? "true" : "false") + ">.");

      if (matchingRequired && !matchedVersion) {
        location.reload(true);
      }  
    },
    (err) => {
      m1port.handleFatalServerError();
      m1port.logger.error("Error fetching patient-app-version data from server. ", err);
    }
  );
}

window.addEventListener("load", () => {
  QConnector.fetch(
    { "action": ReqActions.INTL_DATA },
    (data) => {
      const uiData = data.ui;
      const localDb = window.localStorage;
        
      const translations = {};
      const languages = [];
      for (const lang in uiData) {
        // only active languages from DB...
        if (data.languages[lang]) {
          translations[lang] = JSON.parse(uiData[lang]);
          languages.push(data.languages[lang]);
        }
      }
    
      let uiLang = localDb.getItem("m1port_patient_lang");
      if(!uiLang || !translations[uiLang]) {
        uiLang = "de";
        localDb.setItem("m1port_patient_lang", uiLang);
      }
    
      m1port.language = uiLang;
      m1port.languages = languages;
      m1port.countries = data.countries;
      m1port.translations = translations;
    
      if(!window.localStorage.getItem("m1port_patient_debug")) {
        m1port.logger = { 
          "debugStatus": false, 
          log: function (){}, 
          error: console.error 
        };
      }
    
      const app = createApp(App);
      app.provide("m1port", m1port);
      app.provide("qconnector", QConnector);
      app.mount('#app');
    },
    (err) => {
      m1port.handleFatalServerError();
      m1port.logger.error("Error fetching intl data from server. ", err);
    }
  );

  if (window.__m1port && window.__m1port.stage != 'dev') {
    window.addEventListener('contextmenu', function (e) { 
      e.preventDefault(); 
    }, false);
  }

  m1port.prepare();
});
