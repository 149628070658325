<template>
    <div class="header">

      <div class="container"> 
        <div class="group">
          <AppClockBlock />
          <img class="logo" alt="M1 Logo" src="./../assets/logo.svg">
          <span v-if="getAppStage()" :class="('app-stage ' + getAppStage())">{{ this.getAppStage() }}</span>
        </div>
      </div>

      <div class="container full center">
        <p class="info-top">{{ this.infoMessage }}</p>
        <p :class="'info-top-subtitle ' + (this.progress > 0 ? 'progress-text' : '')">
            {{ 
              this.progress > 0 
              ? this.m1port.t('labels.screens.progress') + ": " + this.progress + "%"
              : this.infoMessage2 
            }}
        </p>
      </div>

      <div class="container"> 
        <AppHeaderMenuBlock
          @terminate-sheet="onTerminateSheet"
          @sheet-go-next="onSheetGoNext"
          @modal-window-show="onModalWindowShow" />
      </div>
    </div>
  </template>
  
  <script>
  import AppHeaderMenuBlock from './AppHeaderMenuBlock.vue';
  import AppClockBlock from './AppClockBlock.vue';

  import Events from './../model/Events.js';
  
  export default {
    name: 'AppHeader',
    inject: ['m1port'],

    props: {
      progress: Number
    },

    components: {
      AppHeaderMenuBlock,
      AppClockBlock
    },
    
    methods: {
      getAppStage() {
        if (window.__m1port && typeof window.__m1port == 'object') {
          const wm1p = window.__m1port;
          if (wm1p.stage && typeof wm1p.stage == "string") {
            if (wm1p.stage == "dev" || wm1p.stage == "test") {
              return wm1p.stage;
            }
          }
        }
        return '';
      },

      onModalWindowShow() {
        this.m1port.logger.log(
          Events.MODAL_WINDOW_SHOW
          + " - Receiver: AppHeader");
        this.$emit(Events.MODAL_WINDOW_SHOW);
      },

      onSheetGoNext() {
        this.infoMessage2 = this.m1port.t('labels.screens.progress') 
          + ": " + this.m1portgetProgress();
      },
      
      onTerminateSheet() {
        this.m1port.logger.log(Events.TERMINATE_SHEET
          + " - Receiver: AppHeader");
        this.infoMessage = this.m1port.t('appTitle');
        this.infoMessage2 = this.m1port.t('appSubTitle');
        this.$emit(Events.TERMINATE_SHEET);
      }
    },

    data() {
      return {
        intl: this.m1port.t,
        infoMessage: this.m1port.t('appTitle'),
        infoMessage2: this.m1port.t('appSubTitle')
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      padding: 0;
      background: rgba(235, 235, 235, 1);
      border-bottom: 2px solid gray;
      border-radius: 5px 5px 0 0;
      box-sizing: border-box;
    }

    .group {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }
    
    .container {      
      max-height: 6em;
      margin: 0;
      padding: 0.75em;
      width: 25%;
    }

    .container.full {
      flex: 1;
    }

    center {
      text-align: center;
    }
    
    .logo {
      margin: 0.5em 1em;
      width: 6em;
    }

    .info-top {
      font-size: 1.75em;
      color: #ee7f00;
      margin: 0;
      padding: 0;
      font-weight: bold;
    }

    .info-top-subtitle {
      margin: 0.2em 0;
      padding: 0;
      font-weight: normal;
      font-size: 1.35em;
      font-family: "times";
    }

    .progress-text {
      font-weight: bold;
    }

    .app-stage {
      display: inline-block;
      padding: 0.5em;
      border: 1px solid black;
      font-size: 1.2em;
      border-radius: 5px;
    }

    .app-stage.dev {
      background: red;
      color:white;
    }

    .app-stage.test {
      background: steelblue;
      color:white;
    }
  
  </style>
  