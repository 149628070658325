<template>
  <div class="block">
      <div v-if="!(this.m1port.currentTask == Tasks.WELCOME_PATIENT && this.m1port.activeWelcomeSheetNumber == 0)"
        class="arrow-left" v-on:click="onClicked" @touchstart="onClicked"></div>
  </div>
</template>

<script>

import Events from '@/model/Events.js';
import Tasks from '@/model/Tasks.js';

export default {
  name: 'CtrlPrev',
  inject: ['m1port'],

  methods: {
    onClicked() {
      if (this.m1port.currentTask == Tasks.WELCOME_PATIENT
        && this.m1port.activeWelcomeSheetNumber == 0) {
        return;
      }
      this.m1port.logger.log(Events.SHEET_GO_PREV
        + " - Sender: CtrlPrev");
      this.$emit(Events.SHEET_GO_PREV);
    }
  },

  data() {
    return {
      Tasks: Tasks
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .block {
    width: 4em;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;
    align-content: center;

    background: rgba(235, 235, 235, 1);
    padding: 0;
    margin: 0; 
  }

  .arrow-left {
    width: 0; 
    height: 0; 
    border-top: 1.5em solid transparent;
    border-bottom: 1.5em solid transparent; 
      
    border-right: 1.5em solid #ee7f00; 
  }

  .arrow-left:hover {
    border-right: 1.5em solid black; 
  }
</style>  